






































import { defineComponent, PropType } from "@vue/composition-api";
import { Cell, CellGroup, Radio, RadioGroup, Button, NavBar, Switch, CheckboxGroup, Checkbox, Toast, Dialog, Field } from "vant";
import axios from "@/helpers/axios";

interface Site {
  id: number;
  siteName: string;
  chargeRefundMin: number;
  chargeRefundMinYuan: number;
  chargeRefundEnable: boolean;
}

export default defineComponent({
  components: {
    [CellGroup.name]: CellGroup,
    [Cell.name]: Cell,
    [Radio.name]: Radio,
    [RadioGroup.name]: RadioGroup,
    [Button.name]: Button,
    [NavBar.name]: NavBar,
    [Switch.name]: Switch,
    [CheckboxGroup.name]: CheckboxGroup,
    [Checkbox.name]: Checkbox,
    [Dialog.Component.name]: Dialog.Component,
    [Field.name]: Field
  },
  props: {
    siteList: { type: Array as PropType<Site[]> }
  },
  name: "edit-charge-refund",
  data() {
    return {
      _list: [] as Site[],
      list: [] as Site[],
      checked: [] as number[],
      selectMode: 1,
      multiMode: false,
      currentSite: undefined as undefined | Site,
      showDialog: false,
      chargeRefundMinYuan: 1
    };
  },
  watch: {
    selectMode(newVal) {
      if (newVal == 1) {
        this.list = this._list;
      } else if (newVal == 2) {
        this.list = this._list?.filter(item => item.chargeRefundEnable === true) || [];
      } else if (newVal == 3) {
        this.list = this._list?.filter(item => item.chargeRefundEnable === false) || [];
      }
    },
    multiMode(newVale) {
      if (newVale === false) {
        this.checked = [];
      }
    }
  },
  computed: {
    chargeRefundMin(): number {
      return Math.floor(this.chargeRefundMinYuan * 100);
    }
  },
  created() {
    this._list = this.siteList || [];
    this.list = this._list;
  },
  methods: {
    multiCheckAll() {
      this.checked = this.list.reduce((v, site) => {
        v.push(site.id);
        return v;
      }, [] as number[]);
    },
    switchChange(site: Site) {
      this.currentSite = site;
      if (!site.chargeRefundEnable) {
        // false
        this.changeRefundMin(0);
        return;
      }
      site.chargeRefundEnable = !site.chargeRefundEnable; // prevent default
      this.showDialog = true;
    },
    multiEdit(res: boolean) {
      this.currentSite = undefined;
      if (res) {
        this.showDialog = true;
      } else {
        this.changeRefundMinMulti(0);
      }
    },
    onDialogConfirm() {
      if (this.multiMode) {
        this.changeRefundMinMulti(this.chargeRefundMin);
      } else {
        this.changeRefundMin(this.chargeRefundMin);
      }
    },
    async changeRefundMin(chargeRefundMin: number) {
      const site = this.currentSite;
      if (!site) return;
      const postData = { siteIdList: [site.id], chargeRefundMin: chargeRefundMin };
      const res = await axios
        .post("/api/manage/updateSiteChargeRefund", postData)
        .then(() => true)
        .catch(() => false);
      if (res) {
        this.$emit("change", postData);
      }
    },
    async changeRefundMinMulti(chargeRefundMin: number) {
      if (this.checked.length == 0) {
        Toast.fail("没有选中");
        return;
      }
      const postData = { siteIdList: this.checked, chargeRefundMin: chargeRefundMin };
      const res = await axios
        .post("/api/manage/updateSiteChargeRefund", postData)
        .then(() => true)
        .catch(() => false);
      if (res) {
        Toast.success("操作成功");
        this.checked = [];
        this.multiMode = false;
        this.$emit("change", postData);
      }
    }
  }
});
