






























import { defineComponent, PropType } from "@vue/composition-api";
import { Cell, CellGroup, Radio, RadioGroup, Button, NavBar, Switch, CheckboxGroup, Checkbox, Toast } from "vant";
import axios from "@/helpers/axios";

interface Site {
  id: number;
  siteName: string;
  chargeNotifyEnable: boolean;
}

export default defineComponent({
  components: {
    [CellGroup.name]: CellGroup,
    [Cell.name]: Cell,
    [Radio.name]: Radio,
    [RadioGroup.name]: RadioGroup,
    [Button.name]: Button,
    [NavBar.name]: NavBar,
    [Switch.name]: Switch,
    [CheckboxGroup.name]: CheckboxGroup,
    [Checkbox.name]: Checkbox
  },
  props: {
    siteList: { type: Array as PropType<Site[]> }
  },
  name: "edit-charge-notify",
  data() {
    return {
      _list: [] as Site[],
      list: [] as Site[],
      checked: [] as number[],
      selectMode: 1,
      multiMode: false
    };
  },
  watch: {
    selectMode(newVal) {
      if (newVal == 1) {
        this.list = this._list || [];
      } else if (newVal == 2) {
        this.list = this._list?.filter(item => item.chargeNotifyEnable === true) || [];
      } else if (newVal == 3) {
        this.list = this._list?.filter(item => item.chargeNotifyEnable === false) || [];
      }
    },
    multiMode(newVale) {
      if (newVale === false) {
        this.checked = [];
      }
    }
  },
  created() {
    this._list = this.siteList || [];
    this.list = this._list;
  },
  methods: {
    multiCheckAll() {
      this.checked = this.list.reduce((v, site) => {
        v.push(site.id);
        return v;
      }, [] as number[]);
    },
    async switchChange(site: Site) {
      const postData = { siteIdList: [site.id], chargeNotifyEnable: site.chargeNotifyEnable };
      const res = await axios
        .post("/api/manage/updateSiteChargeNotify", postData)
        .then(() => true)
        .catch(() => false);
      if (!res) {
        site.chargeNotifyEnable = !site.chargeNotifyEnable;
      } else {
        this.$emit("change", postData);
      }
    },
    async multiEdit(chargeNotifyEnable: boolean) {
      if (this.checked.length == 0) {
        Toast.fail("没有选中");
        return;
      }
      const postData = { siteIdList: this.checked, chargeNotifyEnable: chargeNotifyEnable };
      const res = await axios
        .post("/api/manage/updateSiteChargeNotify", postData)
        .then(() => true)
        .catch(() => false);
      if (res) {
        Toast.success("操作成功");
        this.checked = [];
        this.multiMode = false;
        this.$emit("change", postData);
      }
    }
  }
});
