


















import { defineComponent } from "@vue/composition-api";
import { Cell, CellGroup } from "vant";
import axios from "@/helpers/axios";
import EditChargeNotify from "./edit-charge-notify.vue";
import EditChargeRefund from "./edit-charge-refund.vue";

interface Site {
  id: number;
  isShow: number;
  chargeNotifyEnable: boolean;
  chargeRefundMin: number;
  chargeRefundEnable: boolean;
}

export default defineComponent({
  components: {
    [CellGroup.name]: CellGroup,
    [Cell.name]: Cell,
    EditChargeNotify,
    EditChargeRefund
  },
  data() {
    return {
      edit: "",
      siteList: [] as Site[]
    };
  },
  created() {
    this.getSiteList();
  },
  computed: {
    showCount(): number {
      return this.siteList.filter(site => site.isShow === 1).length;
    },
    notShowCount(): number {
      return this.siteList.filter(site => site.isShow === 0).length;
    },
    chargeNotifyStat(): string {
      const enableCount = this.siteList.filter(s => s.chargeNotifyEnable === true).length;
      return enableCount == this.siteList.length ? "全部开启" : enableCount === 0 ? "全部关闭" : "部分开启";
    },
    chargeRefundStat(): string {
      const enableCount = this.siteList.filter(s => s.chargeRefundMin > 0).length;
      return enableCount == this.siteList.length ? "全部开启" : enableCount === 0 ? "全部关闭" : "部分开启";
    }
  },
  methods: {
    async getSiteList() {
      const res: Site[] = await axios
        .post("/api/manage/getSiteStat")
        .then(res => res.data.data)
        .catch(() => undefined);
      if (!res) return;
      const list = res
        .filter(site => site.isShow === 1 || site.isShow === 0)
        .map(site => {
          site.chargeRefundEnable = site.chargeRefundMin > 0;
          return site;
        });
      this.siteList = list;
    },
    onChargeNotifyChange(data: { siteIdList: number[]; chargeNotifyEnable: boolean }) {
      this.siteList.filter(site => data.siteIdList.indexOf(site.id) !== -1).forEach(site => (site.chargeNotifyEnable = data.chargeNotifyEnable));
    },
    onChargeRefundChange(data: { siteIdList: number[]; chargeRefundMin: number }) {
      this.siteList
        .filter(site => data.siteIdList.indexOf(site.id) !== -1)
        .forEach(site => {
          site.chargeRefundMin = data.chargeRefundMin;
          site.chargeRefundEnable = site.chargeRefundMin > 0;
        });
    }
  }
});
